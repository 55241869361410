import React, { useEffect, useState } from 'react';
import { CircularProgress, Container } from '@material-ui/core';
import People from '@material-ui/icons/People';
import "./styles.css";
import { useParams } from "react-router-dom";
import { getCurrentClientCases } from "../../actions/cases";
import { getCurrentClientInvoices } from "../../actions/invoices";
import { getCasePercentage } from "../../actions/casePercentage";
import { getAllRatingsByContact } from '../../actions/caseRatings';
import { useSelector, useDispatch } from 'react-redux';
import PendingRequestBox from '../Home/PendingRequestBox/PendingRequestBox';
import Assignment from '@material-ui/icons/Assignment';
import UserBox from './UserBox/UserBox';
import InvoiceGrid from './InvoiceGrid/InvoiceGrid';
import AttachMoney from '@material-ui/icons/AttachMoney';
import CommentComponent from './Comment/CommentComponent';
import {postNewComment} from "../../actions/comments";
import decode from 'jwt-decode';
import {useHistory} from 'react-router-dom';
import Star from '@material-ui/icons/Star';
import Check from '@material-ui/icons/Check';
import Comment from '@material-ui/icons/Comment';
import RatingView from '../RatingView/RatingView';
import Alert from 'react-bootstrap/Alert'
import InfoIcon from '@material-ui/icons/Info';
import Rating from '@material-ui/lab/Rating';
import { TEXT_INPUT_VALIDATION } from '../../constants/regexValidations';

import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'


const CaseView = ({isRating}) => {
	
	let { id } = useParams();
	let { rating } = useParams();
	const history = useHistory();
	const [show, setShow] = useState(true);
	const [interval, setInterval] = useState(0);
	const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
	const [isRatingCase, setIsRatingCase] = useState(false);
	const cases = useSelector((state) => state.cases);
	const currentCase = Object.values(cases).filter((element, index) => {
		if (cases[index].id == id) {
			return true;
		}
	})

	const commentTitle = React.createRef();

	const comment = React.createRef();
	
	const formatDate = (date)=>{
		if(date){
			var myDate = new Date(date.slice(0, date.length-10));
		return myDate.toLocaleDateString("es-DO", {year: 'numeric', month: 'numeric', day: 'numeric'})
		}
	}

	const initialState = {title: "", comment: " ", caseId: id, commentedOn: new Date(), commentedBy: user.result.name, isIntern: false};
	const [formData, setFormData] = useState(initialState);
	const dispatch = useDispatch();


	const logout = () =>{
        dispatch({type: 'LOGOUT'});
    
        setUser(null);
        history.push('/auth');
      }
    
    useEffect(() => {
    
        const token = user?.token;
    
        if (token) {
          const decodedToken = decode(token);
    
          if (decodedToken.exp * 1000 < new Date().getTime()){
            logout();
          }else{
            dispatch(getCurrentClientCases());
			dispatch(getCasePercentage(id));
			dispatch(getCurrentClientInvoices());
			dispatch(getAllRatingsByContact(user.id));
			componentDidMount();
			componentWillUnmount();
          }
          
          }
        
    
        setUser(JSON.parse(localStorage.getItem('profile')));
      }, []);

	const componentDidMount = () => {
		setInterval(
			setTimeout(function () {
				setShow(false);
			}, 5000)
		);
	}

	const componentWillUnmount = () => {
		setInterval(clearTimeout(interval));
	}

	const allInvoices = useSelector((state) => state.invoices);

	const comments = useSelector(state => state.comment.result);

	const ratingsOfCurrentUser = useSelector(state => state.ratingsOfUser);

	const currentCaseAndContactRate = Object.values(ratingsOfCurrentUser).filter((item, index) => {
        if (ratingsOfCurrentUser[index].case == currentCase[0]?.id) {
            return ratingsOfCurrentUser[index].surveyData;
        }
    });

	const allInvoicesByCurrentCase = Object.values(allInvoices).filter((value, index) => {
		if (allInvoices[index].caseID == id && (allInvoices[index].status == 3 || allInvoices[index].status == 4)) {
			return true
		}
	});

	const requestsWithOutFiles = currentCase[0]?.caseFileRequests.filter((value, index) => {
		if (!currentCase[0].caseFileRequests[index].file) {
			return true
		} else {
			return false
		}
	})

	const SetStatus = (caseStatus) => {

		switch (caseStatus) {
			case 2:
				return "Pendiente aprobación de ITBS"

			case 3:
				return "Caso aceptado"

			case 4:
				return "Caso cerrado"
			default: return "N/A"

		}
	}

	const handleSubmit = (e)=>{
		e.preventDefault();
		dispatch(postNewComment(formData));
		dispatch(getCurrentClientCases());
		commentTitle.current.value = "";
		comment.current.value = "";
	}

	const handleChange = (e) => {
		e.target.value = e.target.value.replace(TEXT_INPUT_VALIDATION, '');
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

	const handleClick = (e)=>{
		setIsRatingCase(!isRatingCase)
	}

	return (
		currentCase.length ?
		
		(isRating ? <RatingView currentCase={currentCase[0]} handleClick={handleClick}/> : 
				
		<>
		<div className="album py-5 bg-light">
			<div className="container">
				<div className="row mt-5 mb-4">
					<div className="col">
						<h4><i className="fas fa-database"></i> Caso: {currentCase[0].name}</h4> 
					</div>

					
					{
						
						currentCase[0].status == 4 && currentCase[0].caseSurveyInfo?.surveyType > 0 && (!currentCase[0].caseSurveyInfo.sendSurvey ? 
						

							<div className="col-4">
								
							<Alert variant="info">Este caso ha sido calificado <InfoIcon/> </Alert>
							</div> : 
							<div className="col-2">  
							<a href={`/cases/${id}/rating`} className="customWhiteText">
							<button className="btn-lg btn-info customButtonQualified" onClick={handleClick}><Star className="mb-1"/> 
							<label className="customButtonLabel customButtonQualified">
								Calificar</label>
							</button>
							</a>
							</div> )
					}

					
				</div>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item"><a href="/cases">Casos</a></li>
						<li className="breadcrumb-item active" aria-current="page">{currentCase[0].name}</li>
					</ol>
				</nav>


				<dl className="row mt-5 mb-5">

					<dt className="col-sm-3">Numero de caso: </dt>
					<dd className="col-sm-9">{currentCase[0].id}</dd>
					<dt className="col-sm-3">Estado:</dt>
					<dd className="col-sm-9"><span className="badge bg-warning text-dark">{SetStatus(currentCase[0].status) + " "}</span></dd>
					<dt className="col-sm-3">Servicios:</dt>
					<dd className="col-sm-9">{currentCase[0].services ? Object.keys(currentCase[0].services).map(
						(value, index) => {
							return currentCase[0].services[index].name;
						}
					) : <p>N/A</p>}</dd>
					{currentCaseAndContactRate.length > 0 && currentCaseAndContactRate[0].averageRate && <>
						<dt className="col-sm-3">Calificación: </dt>
					<dd className="col-sm-9"><Rating value={currentCaseAndContactRate[0].averageRate} readOnly={true}/> </dd>
					</>}
					<dt className="col-sm-3">Fecha de propuesta:</dt>
					<dd className="col-sm-9">{formatDate(currentCase[0].proposalDate)}</dd>
					<dt className="col-sm-3">Honorarios:</dt>
					<dd className="col-sm-9"><strong>{(currentCase[0].billingInfo.honoraries).toLocaleString('en-US', {
						style: 'currency',
						currency: 'USD'
					}) + " " + currentCase[0].billingInfo.currency.currency}</strong></dd>
				</dl>
				<div className="row mt-5 mb-4">
					<div className="col">
						<h4><Assignment /> Entregables pendientes</h4>
					</div>
				</div>
				{
					requestsWithOutFiles.length > 0 ?
						Object.keys(currentCase[0].caseFileRequests).map((request, index) => {
							if (!currentCase[0].caseFileRequests[index].file) {
								return <PendingRequestBox
									key={currentCase[0].caseFileRequests[index].id}
									case={currentCase[0]}
									request={currentCase[0].caseFileRequests[index]}
								>

								</PendingRequestBox>
							}
						}) : show ? <CircularProgress /> : <p>No hay entregables pendientes.</p>
				}
				<div className="row mt-5 mb-4">
					<div className="col">

						<h4> <People />  Equipo:</h4>
					</div>
				</div>

				<div className="row">
					<div className="col">
						<div className="list-group">

							{
								currentCase[0].assignedStaff.length > 0 ?
									Object.keys(currentCase[0].assignedStaff).map((request, index) => {
										return <UserBox
											key={currentCase[0].assignedStaff[index]?.id}
											currentUser={currentCase[0].assignedStaff[index]}
										>

										</UserBox>
									}) : show ? <CircularProgress /> : <p>No hay miembros de equipo agregados al caso.</p>
							}


						</div>
					</div>
				</div>
				<div className="row mt-5 mb-4">
					<div className="col">
						<h4> <AttachMoney/> Facturación:</h4>
					</div>
				</div>
				<div className="row">
					<div className="col">
						{
							allInvoicesByCurrentCase.length > 0 ? 
							<InvoiceGrid invoices={allInvoicesByCurrentCase} currentCase={currentCase[0]}/> :
							show ? <CircularProgress /> : <p>Actualmente no hay facturas emitidas o pagadas en este caso.</p>
						}
					</div>
				</div>

							
				<div className="container customBackground">
				<div className="row mt-5 mb-4">
					<div className="col">
						<form onSubmit={handleSubmit}>
							<h4> <Comment/> Comentario:</h4>


									<dl className="row mt-5">
										<dt className="col-sm-3 mt-2"><strong><h5>Título del comentario:</h5></strong></dt>
										<dd className="col-sm-5"><input placeholder="Escribe el título aquí..." className="form-control" type="text" name="title" onChange={handleChange} ref={commentTitle} required={true}></input></dd>
									</dl>

									<dl className="row mt-5">
										<dt className="col-sm-3 mt-2"><strong><h5>Nuevo commentario relacionado con este caso:</h5></strong></dt>
										<dd className="col-sm-9"><textarea placeholder="Escribe el comentario aquí..." className="form-control" name="comment" onChange={handleChange} ref={comment} required={true} rows="5" cols="100"></textarea></dd>
									</dl>

									<dl className="row">
										<dt className="col-sm-10 mt-2"></dt>
										<dd className="col-sm-2"><button className="btn btn-lg btn-info ml-4" type="submit" > <Check/><span> Enviar</span></button></dd>
									</dl>
								
						</form>
					</div>
				</div>
				
				<div className="row ">
					<div className="col">
						{
							comments ? Object.keys(comments).map((request, index) => {
								return <CommentComponent
										key={comments[index].id}
										caseComment={comments[index]}
									>

									</CommentComponent>

							}) : currentCase[0].caseComment ?
							Object.keys(currentCase[0].caseComment).map((request, index) => {
								return <CommentComponent
										key={currentCase[0].caseComment[index].id}
										caseComment={currentCase[0].caseComment[index]}
									>

									</CommentComponent>

							}) : show ? <CircularProgress /> : <p>No hay comentarios realizados.</p>
						}

					</div>
				</div>
				</div>

			</div>
		</div>
	</>)
			
			: <>
				<Container >
					<CircularProgress className="customCenter" />
				</Container>
			</>
	)
}

export default CaseView;
